import React from "react";
import { motion } from "framer-motion";
import InputField from "./InputField";

const SignUpForm = ({
  signUpData,
  handleChange,
  handleSubmit,
  loading,
  passwordError,
  validatePassword,
}) => {
  const isSignUpValid =
    signUpData.email &&
    signUpData.password &&
    signUpData.first_name &&
    signUpData.last_name &&
    validatePassword(signUpData.password);

  return (
    <motion.form
      onSubmit={handleSubmit}
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{ duration: 0.2 }}
      className="space-y-6"
    >
      <div className="py-4 space-y-4 ">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            value={signUpData.first_name}
            onChange={(e) => handleChange("first_name")(e)}
            placeholder="Primer nombre"
            name="first_name"
          />
          <InputField
            value={signUpData.middle_name}
            onChange={(e) => handleChange("middle_name")(e)}
            placeholder="Segundo nombre (opc.)"
            name="middle_name"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputField
            value={signUpData.last_name}
            onChange={(e) => handleChange("last_name")(e)}
            placeholder="Primer apellido"
            name="last_name"
          />
          <InputField
            value={signUpData.second_last_name}
            onChange={(e) => handleChange("second_last_name")(e)}
            placeholder="Segundo apellido (opc.)"
            name="second_last_name"
          />
        </div>

        <InputField
          value={signUpData.email}
          onChange={(e) => handleChange("email")(e)}
          placeholder="Ingresa tu correo electrónico"
          type="email"
          name="email"
        />

        <InputField
          value={signUpData.password}
          onChange={(e) => handleChange("password")(e)}
          placeholder="Crea una contraseña"
          isPassword={true}
          name="new-password"
          error={passwordError}
        />
      </div>

      <button
        type="submit"
        disabled={!isSignUpValid || loading}
        className="w-full bg-blue-600 border-none text-white py-3 rounded-2xl hover:bg-blue-800 font-['Fredoka'] transition font-medium text-lg disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {loading ? "Creando cuenta..." : "Crear Cuenta"}
      </button>
    </motion.form>
  );
};

export default SignUpForm;
