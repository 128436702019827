import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Shield,
  FileText,
  Database,
  Book,
  ChevronRight,
  Newspaper,
  MessageSquare,
  Users,
  Home,
} from "lucide-react";

const IndexSection = ({ title, items, onItemClick }) => (
  <div className="space-y-4">
    <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
    <div className="grid md:grid-cols-2 gap-4">
      {items.map((item) => (
        <button
          key={item.id}
          onClick={() => onItemClick(item.id)}
          className="flex items-center justify-between p-4 bg-white rounded-lg border border-gray-200 hover:border-blue-500 transition-all group"
        >
          <div className="flex items-center gap-3">
            <div className="text-gray-600 group-hover:text-blue-500">
              {item.icon}
            </div>
            <span className="font-medium text-gray-700 group-hover:text-blue-600">
              {item.label}
            </span>
          </div>
          <ChevronRight className="w-5 h-5 text-gray-400 group-hover:text-blue-500" />
        </button>
      ))}
    </div>
  </div>
);

const Breadcrumb = ({ currentSection, currentContent, onHomeClick }) => (
  <div className="flex items-center gap-2">
    <button
      onClick={onHomeClick}
      className="flex items-center gap-1 text-sm font-medium text-gray-600 hover:text-blue-600"
    >
      <Home className="w-4 h-4" />
      <span>Inicio</span>
    </button>
    {currentSection !== "home" && (
      <>
        <ChevronRight className="w-4 h-4 text-gray-400" />
        <span className="text-sm font-medium text-blue-600">
          {currentContent?.label}
        </span>
      </>
    )}
  </div>
);

const PolicyIndex = ({
  activeSection = "home",
  logoUrl = "/assets/logos/fluenti-logo-black-140.svg",
  onSectionChange,
}) => {
  const [currentSection, setCurrentSection] = useState(activeSection);

  useEffect(() => {
    setCurrentSection(activeSection);
  }, [activeSection]);

  const handleSectionChange = (sectionId) => {
    setCurrentSection(sectionId);
    if (onSectionChange) {
      onSectionChange(sectionId);
    }
  };

  const legalSection = {
    title: "Legal y Privacidad",
    items: [
      {
        id: "privacy",
        label: "Política de Privacidad",
        icon: <Shield className="w-5 h-5" />,
        content: (
          <div className="space-y-4">
            <h2 className="text-2xl font-bold mb-4">Política de Privacidad</h2>
            <p className="text-gray-700">
              Nos comprometemos a proteger y respetar tu privacidad. Esta
              política establece la base sobre cómo trataremos cualquier
              información personal que recopilemos de ti o que nos proporciones.
            </p>
            <h3 className="text-xl font-semibold mt-6">
              Información que recopilamos
            </h3>
            <p className="text-gray-700">
              Podemos recopilar y procesar los siguientes datos sobre ti:
            </p>
            <ul className="list-disc pl-5 mt-2 space-y-1">
              <li>Información que nos proporcionas al rellenar formularios</li>
              <li>Registros de correspondencia si te contactas con nosotros</li>
              <li>Detalles de tus visitas a nuestro sitio web</li>
            </ul>
          </div>
        ),
      },
      {
        id: "terms",
        label: "Términos y Condiciones",
        icon: <FileText className="w-5 h-5" />,
        content: (
          <div className="space-y-4">
            <h2 className="text-2xl font-bold mb-4">Términos y Condiciones</h2>
            <p className="text-gray-700">
              Al acceder y utilizar este sitio web, aceptas estos términos y
              condiciones en su totalidad.
            </p>
            <h3 className="text-xl font-semibold mt-6">Uso del sitio</h3>
            <p className="text-gray-700">
              El uso de este sitio está sujeto a las siguientes condiciones:
            </p>
            <ul className="list-disc pl-5 mt-2 space-y-1">
              <li>No debes usar este sitio de manera ilegal o fraudulenta</li>
              <li>No debes dañar o interferir con la seguridad del sitio</li>
              <li>Debes cumplir con todas las regulaciones aplicables</li>
            </ul>
          </div>
        ),
      },
      {
        id: "data",
        label: "Tratamiento de Datos",
        icon: <Database className="w-5 h-5" />,
        content: (
          <div className="space-y-4">
            <h2 className="text-2xl font-bold mb-4">Tratamiento de Datos</h2>
            <p className="text-gray-700">
              Explicamos cómo procesamos y protegemos tus datos personales.
            </p>
            <h3 className="text-xl font-semibold mt-6">
              Procesamiento de datos
            </h3>
            <p className="text-gray-700">
              Tus datos personales son procesados para:
            </p>
            <ul className="list-disc pl-5 mt-2 space-y-1">
              <li>Proporcionar nuestros servicios</li>
              <li>Mejorar tu experiencia</li>
              <li>Cumplir con obligaciones legales</li>
              <li>Comunicarnos contigo cuando sea necesario</li>
            </ul>
          </div>
        ),
      },
    ],
  };

  const resourcesSection = {
    title: "Recursos y Ayuda",
    items: [
      {
        id: "manual",
        label: "Manual de Usuario",
        icon: <Book className="w-5 h-5" />,
        content: (
          <div className="space-y-4">
            <h2 className="text-2xl font-bold mb-4">Manual de Usuario</h2>
            <p className="text-gray-700">
              Bienvenido al manual de usuario. Aquí encontrarás toda la
              información necesaria para aprovechar al máximo nuestros
              servicios.
            </p>
            <h3 className="text-xl font-semibold mt-6">Guías rápidas</h3>
            <div className="grid md:grid-cols-2 gap-4 mt-4">
              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Primeros pasos</h4>
                <p className="text-sm text-gray-600">
                  Aprende lo básico para comenzar a usar nuestra plataforma.
                </p>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Funciones avanzadas</h4>
                <p className="text-sm text-gray-600">
                  Descubre todas las características y herramientas disponibles.
                </p>
              </div>
            </div>
          </div>
        ),
      },
      {
        id: "faq",
        label: "Preguntas Frecuentes",
        icon: <MessageSquare className="w-5 h-5" />,
        content: (
          <div className="space-y-4">
            <h2 className="text-2xl font-bold mb-4">Preguntas Frecuentes</h2>
            <p className="text-gray-700">
              Encuentra respuestas a las preguntas más comunes sobre nuestros
              servicios.
            </p>
            <div className="mt-6 space-y-4">
              <div className="border-b pb-4">
                <h3 className="text-lg font-semibold mb-2">¿Cómo empiezo?</h3>
                <p className="text-gray-700">
                  Para comenzar, simplemente regístrate en nuestra plataforma y
                  sigue la guía de inicio rápido.
                </p>
              </div>
              <div className="border-b pb-4">
                <h3 className="text-lg font-semibold mb-2">
                  ¿Necesito equipo especial?
                </h3>
                <p className="text-gray-700">
                  No, solo necesitas un dispositivo con conexión a internet y un
                  navegador actualizado.
                </p>
              </div>
            </div>
          </div>
        ),
      },
    ],
  };

  const communicationSection = {
    title: "Comunicación",
    items: [
      {
        id: "press",
        label: "Sala de Prensa",
        icon: <Newspaper className="w-5 h-5" />,
        content: (
          <div className="space-y-4">
            <h2 className="text-2xl font-bold mb-4">Sala de Prensa</h2>
            <p className="text-gray-700">
              Mantente al día con las últimas noticias y actualizaciones de
              nuestra empresa.
            </p>
            <div className="grid gap-6 mt-6">
              <div className="border-l-4 border-blue-500 pl-4">
                <h3 className="text-lg font-semibold">Último Lanzamiento</h3>
                <p className="text-gray-600 mt-2">
                  Descubre las nuevas características y mejoras en nuestra
                  última versión.
                </p>
              </div>
              <div className="border-l-4 border-green-500 pl-4">
                <h3 className="text-lg font-semibold">Eventos Próximos</h3>
                <p className="text-gray-600 mt-2">
                  Calendario de eventos y conferencias donde participaremos.
                </p>
              </div>
            </div>
          </div>
        ),
      },
      {
        id: "community",
        label: "Comunidad",
        icon: <Users className="w-5 h-5" />,
        content: (
          <div className="space-y-4">
            <h2 className="text-2xl font-bold mb-4">Comunidad</h2>
            <p className="text-gray-700">
              Únete a nuestra comunidad y conecta con otros usuarios.
            </p>
            <div className="grid md:grid-cols-2 gap-6 mt-6">
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-lg font-semibold mb-3">Foro de Usuarios</h3>
                <p className="text-gray-600">
                  Participa en discusiones, comparte experiencias y aprende de
                  otros usuarios.
                </p>
              </div>
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-lg font-semibold mb-3">
                  Eventos Comunitarios
                </h3>
                <p className="text-gray-600">
                  Descubre meetups y eventos organizados por la comunidad.
                </p>
              </div>
            </div>
          </div>
        ),
      },
    ],
  };

  const allSections = [legalSection, resourcesSection, communicationSection];

  const findCurrentContent = () => {
    for (const section of allSections) {
      const item = section.items.find((item) => item.id === currentSection);
      if (item) return item;
    }
    return null;
  };

  const HomePage = () => (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold mb-6">Centro de Documentación</h2>
      <div className="space-y-8">
        {allSections.map((section) => (
          <IndexSection
            key={section.title}
            title={section.title}
            items={section.items}
            onItemClick={handleSectionChange}
          />
        ))}
      </div>
    </div>
  );

  const currentContent = findCurrentContent();

  return (
    <div className="w-full max-w-6xl mx-auto bg-white rounded-3xl mt-4">
      <div className="p-4 md:p-6 border-b border-gray-200 bg-gray-50">
        <div className="flex flex-col gap-4">
          <a href="/">
            <img
              src={logoUrl}
              alt="Logo"
              className="h-8 w-auto md:h-16"
              onError={(e) => {
                e.target.src = "/api/placeholder/40/40";
                e.target.onerror = null;
              }}
            />
          </a>

          <Breadcrumb
            currentSection={currentSection}
            currentContent={currentContent}
            onHomeClick={() => handleSectionChange("home")}
          />
        </div>
      </div>

      <div className="p-4 md:p-6 lg:p-8">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentSection}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
          >
            {currentSection === "home" ? <HomePage /> : currentContent?.content}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default PolicyIndex;
