import React from "react";
import { useSalesContext } from "../../../contexts/SalesContext";
import * as Checkbox from "@radix-ui/react-checkbox";
import { Check, CheckIcon, ExternalLink } from "lucide-react";

const TERMS_URL = "/terminos";

const CheckTermsComponent = () => {
  const { newSalesTerms, setNewSalesTerms } = useSalesContext();

  const handleCheckboxChange = (checked) => {
    setNewSalesTerms(checked);
  };

  return (
    <div className="flex flex-col bg-white rounded-xl gap-3 py-4">
      <div className="flex justify-between gap-4 items-center">
        <label
          htmlFor="terms"
          className="text-md font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 leading-6"
        >
          {"Marca esta casilla para confirmar que has leído y aceptado los "}
          <a
            href={TERMS_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary hover:underline inline-flex items-center"
          >
            términos y condiciones
            <ExternalLink className="ml-1 h-3 w-3" />
          </a>
        </label>
        <Checkbox.Root
          className="flex min-h-8 min-w-8 max-h-8 max-w-8 appearance-none items-center justify-center rounded-md border border-primary bg-white outline-none hover:bg-gray-100 focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 gap-2"
          checked={newSalesTerms}
          onCheckedChange={handleCheckboxChange}
          id="terms"
        >
          <Checkbox.Indicator className="text-primary">
            <CheckIcon strokeWidth={3} color={"#1464ff"} />
          </Checkbox.Indicator>
        </Checkbox.Root>
      </div>
    </div>
  );
};

export default CheckTermsComponent;
